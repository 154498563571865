import {
  CONSUMER_USER,
  PATH_CONSUMER_PRINT_OTHER_EMOTIONS,
  PRODUCT_QTY_MAX,
  PRODUCT_QTY_MIN,
  RETAILER_USER,
  userTypes
} from '../environment/const'
import { DistributorStoreInterfaceSnapshotOut } from '../state-manager/DistributorStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

/**
 * @desc find in object the 'null' and replace with '' (string)
 * @param obj
 */
export const replaceWithStringTheNullInObject = (obj: any) => {
  const newObj = Object.assign({}, obj)

  for (let x in newObj) {
    if (newObj[x] === null) {
      Object.assign(newObj, { [x]: '' })
    }
  }

  return newObj
}

/**
 * @desc clamp products quantity
 * @param currentQuantity
 */
export const clampProductsQuantity = (currentQuantity: number): number => {
  if (currentQuantity > PRODUCT_QTY_MAX) {
    currentQuantity = PRODUCT_QTY_MAX
  }
  if (currentQuantity < PRODUCT_QTY_MIN) {
    currentQuantity = PRODUCT_QTY_MIN
  }
  return currentQuantity
}

/**
 * @desc go to another products route
 */
export const addAnotherProducsToCart = (rootStore?: ShoppingCartStoreInterface) => {
  if (rootStore) {
    const distributor: DistributorStoreInterfaceSnapshotOut = rootStore.getDistributorDetails()
    window.location.href = distributor.url
    return
  }
  window.location.href = PATH_CONSUMER_PRINT_OTHER_EMOTIONS
}

/**
 * Function to replace all the occurences in a string
 * @param str - {String} - The String where replace a char
 * @param find - {String} - The string to replace
 * @param replace - {String} - The string to place
 * @returns {String}
 */
export const strReplaceAll = (str: string, find: string, replace: string): string => str.replace(new RegExp(find, 'g'), replace)

/**
 * @desc function with logics to selfChoose userTypes by prioities
 * @param userTypes
 */
export const selfChooseUserTypes: Function = (userTypes: Array<userTypes>): userTypes => {
  if (userTypes.includes(RETAILER_USER)) {
    return RETAILER_USER
  }
  return CONSUMER_USER
}
